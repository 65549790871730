import React from "react";

import "../styles/centered-box.less";

import { Layout, Row, Col } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

//const sexeForm = Form.create({ name: "homeform" })(TemplateHomeForm)

export default function MentionsLegales() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Mentions légales</h2>
            <Row>
              <Col xs={24} style={{ textAlign: "left" }}>
                <h3>Éditeur du site</h3>
                <p>
                  Patinette
                  <br /> 86, rue Dutot
                  <br /> 75015 PARIS
                  <br /> FRANCE
                  <br />
                  <br /> Société de courtage d’assurance - SAS au capital de
                  100,00 euros, immatriculée au RCS de Paris sous le
                  n°850.778.416 et immatriculée à l’ORIAS sous le n°19004124,
                  siège social : 86 rue Dutot, 75015 Paris
                </p>
                <h3>Directeur de la publication</h3>
                <p>Tristan Dhalluin</p>
                <h3>Hébergement du site</h3>
                <p>
                  Le site patinette.io est hébergé chez OVH.
                  <br />2 rue Kellermann
                  <br />
                  59100 Roubaix
                  <br />
                  France
                </p>
                <h3>Protection des données personnelles</h3>
                <p>
                  Ce site respecte les dispositions de la loi 78-17 du 6 Janvier
                  1978 relative à l&#39;informatique, aux fichiers et aux
                  libertés. En application de cette loi, vous disposez des
                  droits d&#39;opposition (art. 26 de la loi), d&#39;accès (art.
                  34 à 38 de la loi) et de rectification (art. 36 de la loi) des
                  données vous concernant. Ainsi, vous pouvez exiger une mise à
                  jour ou une suppression des informations vous concernant qui
                  sont inexactes, incomplètes, équivoques, périmées ou dont la
                  collecte ou l&#39;utilisation, la communication ou la
                  conservation est interdite.
                </p>
                <h3>Propriété intellectuelle - Fonctionnement et contenus</h3>
                <p>
                  Ce site et son contenu sont protégés, au sens du code de la
                  propriété intellectuelle, en particulier par les droits
                  d&#39;auteur, dessins et modèles et droits des marques. Toute
                  reproduction ou représentation intégrale ou partielle, des
                  textes, des photos et/ou des documents, faites sans
                  l’autorisation écrite préalable de la société PATINETTE est
                  illicite et constitue une contrefaçon sanctionnée pénalement.
                  Malgré tous les soins apportés à la réalisation de ce site et
                  à son actualisation régulière, des erreurs peuvent s&#39;être
                  glissées dans les informations présentées. Les utilisateurs du
                  site procéderont donc à toutes vérifications utiles. Les
                  informations disponibles dans ce site sont fournies à titre
                  indicatif, et sont non contractuelles. A ce titre, Patinette
                  ne saurait supporter aucune demande de dédommagement pour
                  préjudice direct ou indirect subi par l&#39;internaute.
                </p>
                <h3>Liens</h3>
                <p>
                  Les liens hypertextes mis en place dans le cadre du site en
                  direction d&#39;autres ressources présentes sur le réseau
                  Internet et, notamment, vers les partenaires, sont clairement
                  identifiés et font l&#39;objet d&#39;une information et/ou
                  d&#39;une autorisation préalable des sites pointés. Les liens
                  hypertextes en direction d&#39;autres ressources présentes sur
                  le réseau Internet ne sauraient engager la responsabilité de
                  Patinette. Nous ne pourrons pas être tenu responsable des
                  dommages résultant de l&#39;interprétation ou de
                  l&#39;utilisation des informations figurant sur les sites
                  Internet avec lesquels sont créés des liens hypertextes avec
                  notre site. Les utilisateurs du site ne peuvent en aucun cas
                  mettre en place un lien hypertexte en direction du Site sans
                  l&#39;autorisation écrite et préalable de Patinette. Toute
                  demande devra être adressée au Directeur de la publication.
                </p>
                <h3>Traitement des réclamations</h3>
                <p>
                  En cas de problème de souscription, nous vous invitons à nous
                  en faire part en prenant contact avec votre interlocuteur
                  habituel, qui est à votre écoute pour vous apporter une
                  solution. Vous pouvez également nous adresser votre
                  réclamation :
                </p>
                <ul style={{ paddingLeft: 50 }}>
                  <li>par mail: reclamations(AT)patinette.io</li>
                  <li>par courrier : Patinette, 86 rue Dutot, 75015 Paris</li>
                </ul>
                <p> Dans tous les cas, nous nous engageons à :</p>
                <ul style={{ paddingLeft: 50 }}>
                  <li>
                    accuser réception de votre réclamation dans un délai maximum
                    de 10 jours
                  </li>
                  <li>
                    vous apporter une réponse positive ou négative dans un délai
                    maximum de 2 mois à compter de la réception de
                    l&#39;ensemble des éléments de votre réclamation.
                  </li>
                </ul>
                <p>
                  Pour les réclamations liés à la vie du contrat (gestion
                  administrative et sinistre), adresser la réclamation à:
                  <ul style={{ paddingLeft: 50 }}>
                    <li>Stelliant: marketing@stelliant.eu</li>
                  </ul>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
